import { Separator } from '@conte-ltd/components-separator'
import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { useMediaQuery } from '@conte-ltd/components-utils'
import { css } from '@emotion/react'
import React from 'react'
import { Theme } from '../styles/theme'
import { Button } from './button'
import { Container } from './container'
import { InViewFadeUp } from './in-view-fade'
import { Logo } from './logo'

export interface FooterProps {}

export function Footer() {
  const { color, spacing, fontFamily, fontWeight } = useVariables<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)

  return (
    <footer
      css={css`
        position: relative;
        background: ${color.lightgray2};
        padding: ${spacing.xl} 0;

        @media ${bp.lg} {
          align-items: flex-start;
        }
      `}
    >
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <h1
          css={css`
            letter-spacing: unset;
          `}
        >
          <InViewFadeUp>
            <Button variant={'text'} href={'/'}>
              <Logo
                fillColor={color.black}
                css={css`
                  width: 30rem;
                  height: 4.6rem;

                  @media ${bp.lg} {
                    width: 15rem;
                    height: 2.3rem;
                  }
                `}
              />
            </Button>
          </InViewFadeUp>
        </h1>

        {isMobile && (
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing.xs};
              margin-top: ${spacing.md};
              text-align: center;
              font-family: ${fontFamily.default};
              font-weight: ${fontWeight.semibold};
            `}
          >
            <li>
              <Button variant={'text'} href={'/site-policy'}>
                <InViewFadeUp>SITE POLICY</InViewFadeUp>
              </Button>
            </li>
            <li>
              <Button variant={'text'} href={'/privacy-policy'}>
                <InViewFadeUp>PRIVACY POLICY</InViewFadeUp>
              </Button>
            </li>
          </ul>
        )}

        <p
          css={css`
            margin-top: ${spacing.md};
            font-family: ${fontFamily.default};
            line-height: unset;
          `}
        >
          <InViewFadeUp>
            <small>© CONTE inc. All Rights Reserved.</small>
          </InViewFadeUp>
        </p>
      </Container>

      {!isMobile && (
        <ul
          css={css`
            position: absolute;
            top: 25rem;
            right: 0;
            display: flex;
            flex-direction: column;
            gap: ${spacing['3xs']};
            text-align: right;
            font-family: ${fontFamily.default};
            font-weight: ${fontWeight.semibold};

            & > li {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: ${spacing.xs};
              flex-wrap: nowrap;
            }
          `}
        >
          <li>
            <Button variant={'text'} href={'/site-policy'}>
              <InViewFadeUp>SITE POLICY</InViewFadeUp>
            </Button>

            <Separator
              css={css`
                --c-separator-size: 12rem;
                color: ${color.darkgray};
              `}
            />
          </li>
          <li>
            <Button variant={'text'} href={'/privacy-policy'}>
              <InViewFadeUp>PRIVACY POLICY</InViewFadeUp>
            </Button>

            <Separator
              css={css`
                --c-separator-size: 12rem;
                color: ${color.darkgray};
              `}
            />
          </li>
        </ul>
      )}
    </footer>
  )
}

import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { css } from '@emotion/react'
import React, { Ref } from 'react'
import { Theme } from '../styles/theme'
import { NavMenu } from './nav-menu'

export function Header() {
  const { zIndex, spacing, fontSize } = useVariables<Theme>()
  const bp = useBreakpoints()

  return (
    <header
      id={'header'}
      css={css`
        position: fixed;
        top: 3.8rem;
        right: 6rem;
        z-index: ${zIndex.sticky};

        @media ${bp.lg} {
          top: 2.8rem;
        }

        @media ${bp.md} {
          top: 1.8rem;
          right: 5rem;
        }
      `}
    >
      <div
        css={css`
          position: relative;
          z-index: ${zIndex.max};
          font-size: ${fontSize['2xl']};
          letter-spacing: unset;
        `}
      >
        <NavMenu />
      </div>
    </header>
  )
}

export interface HeaderLogoProps {
  controllRefs?: Ref<HTMLSpanElement>[]
}

import { css } from '@emotion/react'
import React, { HTMLAttributes, ReactNode } from 'react'
import { Footer } from './footer'
import { Header } from './header'
import { SEO } from './seo'

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
}

export function Layout({ children, ...other }: LayoutProps) {
  return (
    <div
      css={css`
        position: relative;
      `}
      {...other}
    >
      <SEO />

      <Header />
      <main
        css={css`
          position: relative;
          overflow: hidden;
        `}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

import { css } from '@emotion/react'
import React, { ForwardedRef, forwardRef } from 'react'
import { navigate } from 'gatsby'
import {
  ButtonProps as ButtonPropsBase,
  Button as ButtonBase,
  useMediaQuery,
  useVariables,
  useBreakpoints,
} from '@conte-ltd/components'
import { Theme } from '../styles/theme'
import { PressEvent } from '@react-types/shared'
import { InViewFadeUp } from './in-view-fade'

export interface ButtonProps extends Omit<ButtonPropsBase, 'variant'> {
  variant?: ButtonPropsBase['variant'] | 'underline'
}

function _Button(
  { variant, ...props }: ButtonProps,
  fwRef: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>
) {
  const { children, as, href, onPress, isDisabled } = props
  const { color, fontFamily, fontSize, fontWeight } = useVariables<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)

  const extendedOnPress = href
    ? async (e: PressEvent) => {
        onPress?.(e)

        // ページ切り替え
        await navigate(href ?? '')
      }
    : onPress

  const baseStyle = css`
    font-family: ${fontFamily.default};
    font-size: ${fontSize.sm};
    font-weight: ${fontWeight.semibold};
  `

  return (
    <>
      {variant === 'text' ? (
        <ButtonBase
          css={[
            baseStyle,
            css`
              font-size: inherit;
              font-weight: inherit;
              width: fit-content;

              &[data-disabled] {
                color: ${color.darkgray};
              }
            `,
          ]}
          variant={variant}
          isDisabled={isDisabled}
          as={href ? 'a' : as}
          isPreventDefault={!!href}
          ref={fwRef}
          {...props}
          onPress={extendedOnPress}
        />
      ) : variant === 'underline' ? (
        <ButtonBase
          css={[
            baseStyle,
            css`
              width: fit-content;
              padding-bottom: 2rem;

              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: currentColor;
                transition: width 0.4s ease-in-out;
              }

              &[data-disabled] {
                color: ${color.darkgray};
              }

              &[data-hovered] {
                &::after {
                  width: 60vw;
                }
              }
            `,
          ]}
          variant={'text'}
          isDisabled={isDisabled}
          as={href ? 'a' : as}
          isPreventDefault={!!href}
          ref={fwRef}
          {...props}
          onPress={extendedOnPress}
        >
          <InViewFadeUp delay={0}>{children}</InViewFadeUp>
        </ButtonBase>
      ) : (
        <ButtonBase
          css={[
            baseStyle,
            css`
              --c-button-disabled-text-color: ${color.darkgray};
              --c-button-disabled-color: ${color.lightgray2};

              overflow: hidden;
              width: 216px;
              height: 40px;
              padding: 2px;
              transition: all 0.35s ease-in-out 0.25s;
              color: var(--c-button-text-color);
              background: var(--c-button-color);

              & > span {
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                width: 100%;
                height: 100%;
                color: var(--c-button-text-color);
                background: var(--c-button-color);
                transition: all 0.35s ease-in-out 0.25s;
              }

              &[data-hovered],
              &[data-hovered] span {
                color: var(--c-button-hovered-text-color);
                background: var(--c-button-hovered-color);
                transition: all 0.35s ease-in-out 0.35s;
              }

              &::before,
              &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: var(--c-button-hovered-color);
                transition: all 0.35s ease-in-out 0.5s;
              }

              &::before {
                top: -100%;
                left: -100%;
              }

              &::after {
                bottom: -100%;
                right: -100%;
              }

              &[data-hovered] {
                &::before,
                &::after {
                  transition: all 0.35s ease-in-out;
                }
                &::before {
                  top: 0;
                  left: 0;
                }

                &::after {
                  bottom: 0;
                  right: 0;
                }
              }

              &[data-disabled] {
                &,
                & > span {
                  color: var(--c-button-disabled-text-color);
                  background: var(--c-button-disabled-color);
                }
              }
            `,
            isMobile &&
              css`
                &,
                & > span {
                  color: var(--c-button-hovered-text-color);
                  background: var(--c-button-hovered-color);
                }
              `,
          ]}
          variant={variant}
          isDisabled={isDisabled}
          as={href ? 'a' : as}
          isPreventDefault={!!href}
          ref={fwRef}
          {...props}
          onPress={extendedOnPress}
        >
          <span>
            <InViewFadeUp delay={0}>{children}</InViewFadeUp>
          </span>
        </ButtonBase>
      )}
    </>
  )
}

export const Button = forwardRef(_Button)

export const button = (color: Theme['color']) => {
  return {
    primary: css`
      --c-button-color: ${color.primary};
      --c-button-text-color: ${color.white};
      --c-button-hovered-color: ${color.primary};
      --c-button-hovered-text-color: ${color.white};
    `,
    secondary: css`
      --c-button-color: ${color.secondary};
      --c-button-text-color: ${color.white};
      --c-button-hovered-color: ${color.black};
      --c-button-hovered-text-color: ${color.white};
    `,
    secondary2: css`
      --c-button-color: ${color.secondary};
      --c-button-text-color: ${color.white};
      --c-button-hovered-color: ${color.white};
      --c-button-hovered-text-color: ${color.primary};
    `,
    white: css`
      --c-button-text-color: ${color.white};
      --c-button-color: ${color.darkgray};
      --c-button-hovered-text-color: ${color.black};
      --c-button-hovered-color: ${color.white};
    `,
    black: css`
      --c-button-color: ${color.black};
      --c-button-text-color: ${color.white};
      --c-button-hovered-color: ${color.black};
      --c-button-hovered-text-color: ${color.white};
    `,
    gray: css`
      --c-button-color: ${color.darkgray};
      --c-button-text-color: ${color.white};
      --c-button-hovered-color: ${color.darkgray};
      --c-button-hovered-text-color: ${color.white};
    `,
  }
}

import * as React from 'react'
import {
  Animation,
  useAnimationController,
  useInView,
} from '@conte-ltd/components-animation'
import { HTMLAttributes, useEffect } from 'react'
import { IntersectionOptions } from 'react-intersection-observer'
import { useStyles } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'

export interface InViewTextFadeUpUpProps
  extends HTMLAttributes<HTMLSpanElement | HTMLDivElement>,
    IntersectionOptions {
  as?: 'div' | 'span'
  delay?: number
  duration?: number
  easing?: string
  inView?: boolean
}

export function InViewFadeUp(props: InViewTextFadeUpUpProps) {
  const {
    as = 'span',
    children,
    threshold = 0.2,
    duration = 700,
    delay = 200,
    easing = 'ease-in-out',
    trackVisibility,
    rootMargin,
    root,
    triggerOnce = true,
    skip,
    initialInView,
    inView: propInView,
    ...otherProps
  } = props

  const { display, overflow, size } = useStyles<Theme>()

  const { ref = null, inView } =
    typeof propInView === 'boolean'
      ? { inView: propInView }
      : useInView({
          threshold,
          trackVisibility,
          rootMargin,
          root,
          triggerOnce,
          skip,
          initialInView,
        })

  const { ref: aniRef, play, updatePlaybackRate } = useAnimationController()

  useEffect(() => {
    if (inView) {
      updatePlaybackRate(1)
      play()
    } else {
      updatePlaybackRate(-1)
      play()
    }
  }, [inView])

  const Component = as

  return (
    <Component
      css={[as === 'div' ? display.flex : display.inlineFlex, overflow.hidden]}
      ref={ref}
      {...otherProps}
    >
      <Animation
        keyframes={[
          { opacity: 0, transform: 'translateY(30px)' },
          { opacity: 1, transform: 'translateY(0)' },
        ]}
        options={{ duration, delay, easing }}
        ref={aniRef}
      >
        <Component
          css={[as === 'div' ? display.block : display.inlineBlock, size.full]}
        >
          {children}
        </Component>
      </Animation>
    </Component>
  )
}

export function InViewFadeToLeft(props: InViewTextFadeUpUpProps) {
  const {
    as = 'span',
    children,
    threshold = 0.2,
    duration = 700,
    delay,
    easing = 'ease-out',
    trackVisibility,
    rootMargin,
    root,
    triggerOnce = true,
    skip,
    initialInView,
    inView: propInView,
    ...otherProps
  } = props

  const { display, overflow, size } = useStyles<Theme>()

  const { ref = null, inView } =
    typeof propInView === 'boolean'
      ? { inView: propInView }
      : useInView({
          threshold,
          trackVisibility,
          rootMargin,
          root,
          triggerOnce,
          skip,
          initialInView,
        })

  const { ref: aniRef, play, updatePlaybackRate } = useAnimationController()

  useEffect(() => {
    if (inView) {
      updatePlaybackRate(1)
      play()
    } else {
      updatePlaybackRate(-1)
      play()
    }
  }, [inView])

  const Component = as

  return (
    <Component
      css={[as === 'div' ? display.flex : display.inlineFlex, overflow.hidden]}
      ref={ref}
      {...otherProps}
    >
      <Animation
        keyframes={[
          { opacity: 0, transform: 'translateX(30px)' },
          { opacity: 1, transform: 'translateX(0)' },
        ]}
        options={{ duration, delay, easing }}
        ref={aniRef}
      >
        <Component
          css={[as === 'div' ? display.block : display.inlineBlock, size.full]}
        >
          {children}
        </Component>
      </Animation>
    </Component>
  )
}

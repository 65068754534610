import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { SiteMetaQuery } from '../../graphql-types'

export interface SEOProps {
  title?: string
  description?: string
  image?: string
  lang?: string
}

const query = graphql`
  query SiteMeta {
    site {
      siteMetadata {
        siteUrl
        titleTemplate
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
      }
    }
  }
`

export function SEO({ title, description, image, lang = 'ja' }: SEOProps) {
  const { site } = useStaticQuery<SiteMetaQuery>(query)
  const {
    siteUrl,
    titleTemplate,
    defaultTitle,
    defaultDescription,
    defaultImage,
  } = site?.siteMetadata ?? {}

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      titleTemplate={titleTemplate!}
      title={title || defaultTitle!}
      meta={[
        {
          name: `description`,
          content: description || defaultDescription!,
        },
        {
          name: 'facebook-domain-verification',
          content: 'k4cx0ayjei905ijuim4s4hjocebwcs'
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${image || defaultImage}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description || defaultDescription!,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `thumbnail`,
          content: `${siteUrl}${image || defaultImage}`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description || defaultDescription!,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />
  )
}

import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { css } from '@emotion/react'
import React, { HTMLAttributes } from 'react'
import type { Theme } from '../styles/theme'

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {}

export function Container(props: ContainerProps) {
  const { size, spacing } = useVariables<Theme>()
  const { md } = useBreakpoints()

  return (
    <div
      css={css`
        position: relative;
        max-width: ${size.container.lg};
        margin: 0 auto;

        @media ${md} {
          max-width: unset;
        }
      `}
      {...props}
    />
  )
}
